<template>
  <div class="dbjls">
    <div class="d6-bjls-tit">{{ projectData.project }}-报警连锁</div>
    <div class="content">
      <div class="left-box">
        <div class="lie1">精馏</div>
        <div class="lie2">
          <div class="hang1">
            <div class="item11">回路名称</div>
            <div class="item1">使用开关</div>
            <div class="item1">下限</div>
            <div class="item1">实时值</div>
            <div class="item1">上限</div>
            <div class="item1">联锁值</div>
            <div class="item2">联锁动作</div>
          </div>
          <div class="hang2">
            <div class="item11">原料罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN01 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN01 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALM02', 'DB1V', 'ANALM02', '原料罐液位下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALM02, 'ANALM02', 'DB1V', 'ANALM02')
              "
            >
              {{ infoList.DB1V.ANALM02 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI101', 'DB1S', 'LI101', '原料罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI101, 'LI101', 'DB1S', 'LI101')"
            >
              {{ infoList.DB1S.LI101 }}
            </div>
            <div class="item1"></div>
            <div class="item1"></div>
            <div class="item2">液位高停进料泵</div>
          </div>
          <div class="hang2">
            <div class="item11">塔釜液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN02 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN02 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI104', 'DB1S', 'LI104', '塔釜液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI104, 'LI104', 'DB1S', 'LI104')"
            >
              {{ infoList.DB1S.LI104 }}
            </div>
            <div
              class="item1"
              @click="toCompon(2, 'ANALM04', 'DB1V', 'ANALM04', '塔釜液位上限')"
              @dblclick="
                Cclick(infoList.DB1V.ANALM04, 'ANALM04', 'DB1V', 'ANALM04')
              "
            >
              {{ infoList.DB1V.ANALM04 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM03', 'DB1V', 'ANALAM03', '塔釜液位联锁值')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM03, 'ANALAM03', 'DB1V', 'ANALAM03')
              "
            >
              {{ infoList.DB1V.ANALAM03 }}
            </div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">塔顶压力</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN03 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN03 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'PI103', 'DB1S', 'PI103', '塔顶压力实时值')"
              @dblclick="Cclick(infoList.DB1S.PI103, 'PI103', 'DB1S', 'PI103')"
            >
              {{ infoList.DB1S.PI103 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM05', 'DB1V', 'ANALAM05', '塔顶压力上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM05, 'ANALAM05', 'DB1V', 'ANALAM05')
              "
            >
              {{ infoList.DB1V.ANALAM05 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM06', 'DB1V', 'ANALAM06', '塔顶压力联锁值')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM06, 'ANALAM06', 'DB1V', 'ANALAM06')
              "
            >
              {{ infoList.DB1V.ANALAM06 }}
            </div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">回流罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN04 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN04 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI102', 'DB1S', 'LI102', '回流罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI102, 'LI102', 'DB1S', 'LI102')"
            >
              {{ infoList.DB1S.LI102 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM07', 'DB1V', 'ANALAM07', '回流罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM07, 'ANALAM07', 'DB1V', 'ANALAM07')
              "
            >
              {{ infoList.DB1V.ANALAM07 }}
            </div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">再沸器温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN05 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN05 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALM10', 'DB1V', 'ANALM10', '再沸器温度下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALM10, 'ANALM10', 'DB1V', 'ANALM10')
              "
            >
              {{ infoList.DB1V.ANALM10 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'TI108', 'DB1S', 'TI108', '再沸器温度实时值')"
              @dblclick="Cclick(infoList.DB1S.TI108, 'TI108', 'DB1S', 'TI108')"
            >
              {{ infoList.DB1S.TI108 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM09', 'DB1V', 'ANALAM09', '再沸器温度上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM09, 'ANALAM09', 'DB1V', 'ANALAM09')
              "
            >
              {{ infoList.DB1V.ANALAM09 }}
            </div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">塔顶温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN06 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN06 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM11', 'DB1V', 'ANALAM11', '塔顶温度下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM11, 'ANALAM11', 'DB1V', 'ANALAM11')
              "
            >
              {{ infoList.DB1V.ANALAM11 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'TI110', 'DB1S', 'TI110', '塔顶温度实时值')"
              @dblclick="Cclick(infoList.DB1S.TI110, 'TI110', 'DB1S', 'TI110')"
            >
              {{ infoList.DB1S.TI110 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM12', 'DB1V', 'ANALAM12', '塔顶温度上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM12, 'ANALAM12', 'DB1V', 'ANALAM12')
              "
            >
              {{ infoList.DB1V.ANALAM12 }}
            </div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">产品罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN07 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN07 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM13', 'DB1V', 'ANALAM13', '产品罐液位下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM13, 'ANALAM13', 'DB1V', 'ANALAM13')
              "
            >
              {{ infoList.DB1V.ANALAM13 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI103', 'DB1S', 'LI103', '产品罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI103, 'LI103', 'DB1S', 'LI103')"
            >
              {{ infoList.DB1S.LI103 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(2, 'ANALAM14', 'DB1V', 'ANALAM14', '产品罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM14, 'ANALAM14', 'DB1V', 'ANALAM14')
              "
            >
              {{ infoList.DB1V.ANALAM14 }}
            </div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">冷凝器出口温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN08 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN08 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'TI107', 'DB1S', 'TI107', '冷凝器出口温度实时值')
              "
              @dblclick="Cclick(infoList.DB1S.TI107, 'TI107', 'DB1S', 'TI107')"
            >
              {{ infoList.DB1S.TI107 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(
                  2,
                  'ANALAM15',
                  'DB1V',
                  'ANALAM15',
                  '冷凝器出口温度上限'
                )
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM15, 'ANALAM15', 'DB1V', 'ANALAM15')
              "
            >
              {{ infoList.DB1V.ANALAM15 }}
            </div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">塔釜温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN36 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN36 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'TI102', 'DB1S', 'TI102', '冷凝器出口温度实时值')
              "
              @dblclick="Cclick(infoList.DB1S.TI102, 'TI102', 'DB1S', 'TI102')"
            >
              {{ infoList.DB1S.TI102 }}
            </div>
            <div
              class="item1"
              @click="
                toCompon(
                  2,
                  'ANALAM72',
                  'DB1V',
                  'ANALAM72',
                  '冷凝器出口温度上限'
                )
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM72, 'ANALAM72', 'DB1V', 'ANALAM72')
              "
            >
              {{ infoList.DB1V.ANALAM72 }}
            </div>
            <div class="item1"></div>
            <div class="item2">温度高停电加热</div>
          </div>
        </div>
      </div>
      <div class="left-box">
        <div class="lie1">反应釜</div>
        <div class="lie2">
          <div class="hang1">
            <div class="item11">回路名称</div>
            <div class="item1">使用开关</div>
            <div class="item1">下限</div>
            <div class="item1">实时值</div>
            <div class="item1">上限</div>
            <div class="item1">联锁值</div>
            <div class="item2">联锁动作</div>
          </div>
          <div class="hang2">
            <div class="item11">1#原料罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN09 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN09 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI401', 'DB1S', 'LI401', '原料罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI401, 'LI401', 'DB1S', 'LI401')"
            >
              {{ infoList.DB1S.LI401 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM17', 'DB1V', 'ANALAM17', '原料罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM17, 'ANALAM17', 'DB1V', 'ANALAM17')
              "
            >
              {{ infoList.DB1V.ANALAM17 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM18', 'DB1V', 'ANALAM18', '原料罐液位联锁值')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM18, 'ANALAM18', 'DB1V', 'ANALAM18')
              "
            >
              {{ infoList.DB1V.ANALAM18 }}
            </div>
            <div class="item2">液位高停进料泵</div>
          </div>
          <div class="hang2">
            <div class="item11">2#原料罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN10 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN10 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'LI402', 'DB1S', 'LI402', '2#原料罐液位实时值')
              "
              @dblclick="Cclick(infoList.DB1S.LI402, 'LI402', 'DB1S', 'LI402')"
            >
              {{ infoList.DB1S.LI402 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM19', 'DB1V', 'ANALAM19', '2#原料罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM19, 'ANALAM19', 'DB1V', 'ANALAM19')
              "
            >
              {{ infoList.DB1V.ANALAM19 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM20', 'DB1V', 'ANALAM20', '原料罐液位联锁值')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM20, 'ANALAM20', 'DB1V', 'ANALAM20')
              "
            >
              {{ infoList.DB1V.ANALAM20 }}
            </div>
            <div class="item2">液位高停进料泵</div>
          </div>
          <div class="hang2">
            <div class="item11">2#原料罐温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN11 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN11 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'TI401', 'DB1S', 'TI401', '2#原料罐液位实时值')
              "
              @dblclick="Cclick(infoList.DB1S.TI401, 'TI401', 'DB1S', 'TI401')"
            >
              {{ infoList.DB1S.TI401 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM22', 'DB1V', 'ANALAM22', '2#原料罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM22, 'ANALAM22', 'DB1V', 'ANALAM22')
              "
            >
              {{ infoList.DB1V.ANALAM22 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM20', 'DB1V', 'ANALAM20', '原料罐液位联锁值')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM20, 'ANALAM20', 'DB1V', 'ANALAM20')
              "
            >
              {{ infoList.DB1V.ANALAM20 }}
            </div>
            <div class="item2">温度高停电加热</div>
          </div>
          <div class="hang2">
            <div class="item11">冷凝器出口温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN12 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN12 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(
                  2,
                  'ANALAM24',
                  'DB1V',
                  'ANALAM24',
                  '冷凝器出口温度下限'
                )
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM24, 'ANALAM24', 'DB1V', 'ANALAM24')
              "
            >
              {{ infoList.DB1V.ANALAM24 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'TI405', 'DB1S', 'TI405', '冷凝器出口温度实时值')
              "
              @dblclick="Cclick(infoList.DB1S.TI405, 'TI405', 'DB1S', 'TI405')"
            >
              {{ infoList.DB1S.TI405 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(
                  2,
                  'ANALAM23',
                  'DB1V',
                  'ANALAM23',
                  '冷凝器出口温度上限'
                )
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM23, 'ANALAM23', 'DB1V', 'ANALAM23')
              "
            >
              {{ infoList.DB1V.ANALAM23 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">产品罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN13 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN13 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI405', 'DB1S', 'LI405', '产品罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI405, 'LI405', 'DB1S', 'LI405')"
            >
              {{ infoList.DB1S.LI405 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM25', 'DB1V', 'ANALAM25', '产品罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM25, 'ANALAM25', 'DB1V', 'ANALAM25')
              "
            >
              {{ infoList.DB1V.ANALAM25 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">中间罐液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN14 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN14 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI404', 'DB1S', 'LI404', '中间罐液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI404, 'LI404', 'DB1S', 'LI404')"
            >
              {{ infoList.DB1S.LI404 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM27', 'DB1V', 'ANALAM27', '中间罐液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM27, 'ANALAM27', 'DB1V', 'ANALAM27')
              "
            >
              {{ infoList.DB1V.ANALAM27 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">反应釜液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN14 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN14 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI404', 'DB1S', 'LI404', '反应釜液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI404, 'LI404', 'DB1S', 'LI404')"
            >
              {{ infoList.DB1S.LI404 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM27', 'DB1V', 'ANALAM27', '反应釜液位上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM27, 'ANALAM27', 'DB1V', 'ANALAM27')
              "
            >
              {{ infoList.DB1V.ANALAM27 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">反应釜内温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN16 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN16 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'TI402', 'DB1S', 'TI402', '反应釜内温度实时值')
              "
              @dblclick="Cclick(infoList.DB1S.TI402, 'TI402', 'DB1S', 'TI402')"
            >
              {{ infoList.DB1S.TI402 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM31', 'DB1V', 'ANALAM31', '反应釜内温度上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM31, 'ANALAM31', 'DB1V', 'ANALAM31')
              "
            >
              {{ infoList.DB1V.ANALAM31 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">反应釜内压力</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN32 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN32 ? "使能" : "切除" }}
              </div>
            </div>
            <div class="item1 colorl1"></div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'PI401', 'DB1S', 'PI401', '反应釜内压力实时值')
              "
              @dblclick="Cclick(infoList.DB1S.PI401, 'PI401', 'DB1S', 'PI401')"
            >
              {{ infoList.DB1S.PI401 }}
            </div>
            <div
              class="item1 colorl"
              @click="
                toCompon(2, 'ANALAM63', 'DB1V', 'ANALAM63', '反应釜内压力上限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM63, 'ANALAM63', 'DB1V', 'ANALAM63')
              "
            >
              {{ infoList.DB1V.ANALAM63 }}
            </div>
            <div class="item1 colorl"></div>
            <div class="item2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="content1">
      <div class="left-box">
        <div class="lie1">公用</div>
        <div class="lie2">
          <div class="hang1">
            <div class="item11">回路名称</div>
            <div class="item1">使用开关</div>
            <div class="item1">下限</div>
            <div class="item1">实时值</div>
            <div class="item1">上限</div>
            <div class="item1">联锁值</div>
            <div class="item2">联锁动作</div>
          </div>
          <div class="hang2">
            <div class="item11">储气罐压力</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN29 ? 'green' : 'red'
                }"
              >
                {{ infoList.DB1V.DGALMEN29 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM58', 'DB1V', 'ANALAM58', '储气罐压力下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM58, 'ANALAM58', 'DB1V', 'ANALAM58')
              "
            >
              {{ infoList.DB1V.ANALAM58 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI101', 'DB1S', 'LI101', '储气罐压力实时值')"
              @dblclick="Cclick(infoList.DB1S.LI101, 'LI101', 'DB1S', 'LI101')"
            >
              {{ infoList.DB1S.LI101 }}
            </div>
            <div class="item1" @click="toCompon(2, 'ANALAM57', 'DB1V', 'ANALAM57', '储气罐压力上限')"
              @dblclick="Cclick(infoList.DB1V.ANALAM57, 'ANALAM57', 'DB1V', 'ANALAM57')"
            >
              {{ infoList.DB1V.ANALAM57 }}</div>
            <div class="item1"></div>
            <div class="item2">压力高放空</div>
          </div>
          <div class="hang2">
            <div class="item11">共用水箱液位</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN33 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN33 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM66', 'DB1V', 'ANALAM66', '共用水箱液位下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM66, 'ANALAM66', 'DB1V', 'ANALAM66')
              "
            >
              {{ infoList.DB1V.ANALAM66 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'LI001', 'DB1S', 'LI001_DCS', '共用水箱液位实时值')"
              @dblclick="Cclick(infoList.DB1S.LI001_DCS, 'LI001', 'DB1S', 'LI001_DCS')"
            >
              {{ infoList.DB1S.LI001_DCS }}
            </div>
            <div class="item1" @click="toCompon(2, 'ANALAM65', 'DB1V', 'ANALAM65', '共用水箱液位上限')"
              @dblclick="Cclick(infoList.DB1V.ANALAM65, 'ANALAM65', 'DB1V', 'ANALAM65')"
            >
              {{ infoList.DB1V.ANALAM65 }}</div>
            <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">锅炉蒸汽温度</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN34 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN34 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM68', 'DB1V', 'ANALAM68', '锅炉蒸汽温度下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM68, 'ANALAM68', 'DB1V', 'ANALAM68')
              "
            >
              {{ infoList.DB1V.ANALAM68 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'TI314', 'DB1S', 'TI314', '锅炉蒸汽温度实时值')"
              @dblclick="Cclick(infoList.DB1S.TI314, 'TI314', 'DB1S', 'TI314')"
            >
              {{ infoList.DB1S.TI314 }}
            </div>
            <div class="item1" @click="toCompon(2, 'ANALAM67', 'DB1V', 'ANALAM67', '锅炉蒸汽温度上限')"
              @dblclick="Cclick(infoList.DB1V.ANALAM67, 'ANALAM67', 'DB1V', 'ANALAM67')"
            >
              {{ infoList.DB1V.ANALAM67 }}</div>
              <div class="item1"></div>
            <div class="item2"></div>
          </div>
          <div class="hang2">
            <div class="item11">汽包压力</div>
            <div class="item1">
              <div
                class="qiechu"
                :style="{
                  backgroundColor: infoList.DB1V.DGALMEN35 ? 'green' : 'red',
                }"
              >
                {{ infoList.DB1V.DGALMEN35 ? "使能" : "切除" }}
              </div>
            </div>
            <div
              class="item1 colorl1"
              @click="
                toCompon(2, 'ANALAM69', 'DB1V', 'ANALAM69', '汽包压力下限')
              "
              @dblclick="
                Cclick(infoList.DB1V.ANALAM69, 'ANALAM69', 'DB1V', 'ANALAM69')
              "
            >
              {{ infoList.DB1V.ANALAM69 }}
            </div>
            <div
              class="item1 colorl"
              @click="toCompon(2, 'PI305', 'DB1S', 'PI305', '汽包压力实时值')"
              @dblclick="Cclick(infoList.DB1S.PI305, 'PI305', 'DB1S', 'PI305')"
            >
              {{ infoList.DB1S.PI305 }}
            </div>
            <div class="item1" @click="toCompon(2, 'ANALAM70', 'DB1V', 'ANALAM70', '汽包压力上限')"
              @dblclick="Cclick(infoList.DB1V.ANALAM70, 'ANALAM70', 'DB1V', 'ANALAM70')"
            >
              {{ infoList.DB1V.ANALAM70 }}</div>
              <div class="item1"></div>
            <div class="item2"></div>
          </div>

          
        </div>
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  components: {
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  mixins: [index],
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
  },
};
</script>
<style lang="scss" scoped>
.dbjls {
  .d6-bjls-tit {
    width: 90vw;
    height: 8vh;
    background: url("~@/assets/images/ding1.png") no-repeat center center;
    background-size: 100% 100%;
    margin: 0vh 2vw;
    text-align: center;
    line-height: 7vh;
    color: #0ef7ff;
    font-size: 1.3vw;
    cursor: 'pointer'
  }
  .content {
    margin-top: 2vh;
    // border: 1px solid #fff;
    display: flex;
    .left-box {
      width: 48vw;
      height: 41vh;
      border: 1px solid rgba(0, 228, 255, 0.7);
      margin-left: 1vw;
      display: flex;
      .lie1 {
        height: 41vh;
        width: 5vw;
        color: azure;
        text-align: center;
        line-height: 40vh;
        border-right: 1px solid rgba(0, 228, 255, 0.7);
      }
      .lie2 {
        .hang1 {
          display: flex;
          width: 43vw;
          border-bottom: 1px solid rgba(0, 228, 255, 0.7);

          .item1 {
            width: 6vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
  
          }
          .item11 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);

          }
          .item2 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;

            // border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
        }
        .hang2 {
          display: flex;
          width: 43vw;
          border-bottom: 1px solid rgba(0, 228, 255, 0.7);
          .item11 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
          .item1 {
            width: 6vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #8aeaff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
            cursor: pointer;
            .qiechu {
              width: 4vw;
              height: 3vh;
              line-height: 3vh;
              background-color: #e90a30;
              color: #fff;
            }
          }
          .colorl {
            color: #01ffba;
          }
          .colorl1 {
            color: #fff;
          }
          .item2 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            // border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
        }
      }
    }
  }
  .content1 {
    margin-top: 5vh;
    // border: 1px solid #fff;
    display: flex;
    .left-box {
      width: 48vw;
      height: 20.5vh;
      border: 1px solid rgba(0, 228, 255, 0.7);
      margin-left: 1vw;
      display: flex;
      .lie1 {
        height: 20.5vh;
        width: 5vw;
        color: azure;
        text-align: center;
        line-height:20.5vh;
        border-right: 1px solid rgba(0, 228, 255, 0.7);
      }
      .lie2 {
        .hang1 {
          display: flex;
          width: 43vw;
          border-bottom: 1px solid rgba(0, 228, 255, 0.7);

          .item1 {
            width: 6vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
            
          }
          .item11 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
          .item2 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            // border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
        }
        .hang2 {
          display: flex;
          width: 43vw;
          border-bottom: 1px solid rgba(0, 228, 255, 0.7);
          .item11 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
          .item1 {
            width: 6vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #8aeaff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid rgba(0, 228, 255, 0.7);
            cursor: pointer;
            .qiechu {
              width: 4vw;
              height: 3vh;
              line-height: 3vh;
              background-color: #e90a30;
              color: #fff;
            }
          }
          .colorl {
            color: #01ffba;
          }
          .colorl1 {
            color: #fff;
          }
          .item2 {
            width: 7vw;
            height: 4vh;
            line-height: 4vh;
            text-align: center;
            color: #458ca4;
            // border-right: 1px solid rgba(0, 228, 255, 0.7);
          }
        }
      }
    }
  }
}
</style>