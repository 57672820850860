var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dbjls"},[_c('div',{staticClass:"d6-bjls-tit"},[_vm._v(_vm._s(_vm.projectData.project)+"-报警连锁")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"lie1"},[_vm._v("精馏")]),_c('div',{staticClass:"lie2"},[_vm._m(0),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("原料罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN01 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN01 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALM02', 'DB1V', 'ANALM02', '原料罐液位下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALM02, 'ANALM02', 'DB1V', 'ANALM02')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALM02)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI101', 'DB1S', 'LI101', '原料罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI101, 'LI101', 'DB1S', 'LI101')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI101)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"},[_vm._v("液位高停进料泵")])]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("塔釜液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN02 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN02 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI104', 'DB1S', 'LI104', '塔釜液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI104, 'LI104', 'DB1S', 'LI104')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI104)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALM04', 'DB1V', 'ANALM04', '塔釜液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALM04, 'ANALM04', 'DB1V', 'ANALM04')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALM04)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM03', 'DB1V', 'ANALAM03', '塔釜液位联锁值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM03, 'ANALAM03', 'DB1V', 'ANALAM03')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM03)+" ")]),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("塔顶压力")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN03 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN03 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'PI103', 'DB1S', 'PI103', '塔顶压力实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.PI103, 'PI103', 'DB1S', 'PI103')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.PI103)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM05', 'DB1V', 'ANALAM05', '塔顶压力上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM05, 'ANALAM05', 'DB1V', 'ANALAM05')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM05)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM06', 'DB1V', 'ANALAM06', '塔顶压力联锁值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM06, 'ANALAM06', 'DB1V', 'ANALAM06')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM06)+" ")]),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("回流罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN04 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN04 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI102', 'DB1S', 'LI102', '回流罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI102, 'LI102', 'DB1S', 'LI102')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI102)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM07', 'DB1V', 'ANALAM07', '回流罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM07, 'ANALAM07', 'DB1V', 'ANALAM07')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM07)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("再沸器温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN05 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN05 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALM10', 'DB1V', 'ANALM10', '再沸器温度下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALM10, 'ANALM10', 'DB1V', 'ANALM10')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALM10)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI108', 'DB1S', 'TI108', '再沸器温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI108, 'TI108', 'DB1S', 'TI108')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI108)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM09', 'DB1V', 'ANALAM09', '再沸器温度上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM09, 'ANALAM09', 'DB1V', 'ANALAM09')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM09)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("塔顶温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN06 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN06 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM11', 'DB1V', 'ANALAM11', '塔顶温度下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM11, 'ANALAM11', 'DB1V', 'ANALAM11')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM11)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI110', 'DB1S', 'TI110', '塔顶温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI110, 'TI110', 'DB1S', 'TI110')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI110)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM12', 'DB1V', 'ANALAM12', '塔顶温度上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM12, 'ANALAM12', 'DB1V', 'ANALAM12')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM12)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("产品罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN07 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN07 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM13', 'DB1V', 'ANALAM13', '产品罐液位下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM13, 'ANALAM13', 'DB1V', 'ANALAM13')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM13)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI103', 'DB1S', 'LI103', '产品罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI103, 'LI103', 'DB1S', 'LI103')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI103)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM14', 'DB1V', 'ANALAM14', '产品罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM14, 'ANALAM14', 'DB1V', 'ANALAM14')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM14)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("冷凝器出口温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN08 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN08 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI107', 'DB1S', 'TI107', '冷凝器出口温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI107, 'TI107', 'DB1S', 'TI107')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI107)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(
                2,
                'ANALAM15',
                'DB1V',
                'ANALAM15',
                '冷凝器出口温度上限'
              )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM15, 'ANALAM15', 'DB1V', 'ANALAM15')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM15)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("塔釜温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN36 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN36 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI102', 'DB1S', 'TI102', '冷凝器出口温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI102, 'TI102', 'DB1S', 'TI102')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI102)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(
                2,
                'ANALAM72',
                'DB1V',
                'ANALAM72',
                '冷凝器出口温度上限'
              )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM72, 'ANALAM72', 'DB1V', 'ANALAM72')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM72)+" ")]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"},[_vm._v("温度高停电加热")])])])]),_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"lie1"},[_vm._v("反应釜")]),_c('div',{staticClass:"lie2"},[_vm._m(1),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("1#原料罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN09 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN09 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI401', 'DB1S', 'LI401', '原料罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI401, 'LI401', 'DB1S', 'LI401')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI401)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM17', 'DB1V', 'ANALAM17', '原料罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM17, 'ANALAM17', 'DB1V', 'ANALAM17')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM17)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM18', 'DB1V', 'ANALAM18', '原料罐液位联锁值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM18, 'ANALAM18', 'DB1V', 'ANALAM18')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM18)+" ")]),_c('div',{staticClass:"item2"},[_vm._v("液位高停进料泵")])]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("2#原料罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN10 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN10 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI402', 'DB1S', 'LI402', '2#原料罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI402, 'LI402', 'DB1S', 'LI402')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI402)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM19', 'DB1V', 'ANALAM19', '2#原料罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM19, 'ANALAM19', 'DB1V', 'ANALAM19')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM19)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM20', 'DB1V', 'ANALAM20', '原料罐液位联锁值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM20, 'ANALAM20', 'DB1V', 'ANALAM20')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM20)+" ")]),_c('div',{staticClass:"item2"},[_vm._v("液位高停进料泵")])]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("2#原料罐温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN11 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN11 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI401', 'DB1S', 'TI401', '2#原料罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI401, 'TI401', 'DB1S', 'TI401')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI401)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM22', 'DB1V', 'ANALAM22', '2#原料罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM22, 'ANALAM22', 'DB1V', 'ANALAM22')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM22)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM20', 'DB1V', 'ANALAM20', '原料罐液位联锁值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM20, 'ANALAM20', 'DB1V', 'ANALAM20')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM20)+" ")]),_c('div',{staticClass:"item2"},[_vm._v("温度高停电加热")])]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("冷凝器出口温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN12 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN12 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(
                2,
                'ANALAM24',
                'DB1V',
                'ANALAM24',
                '冷凝器出口温度下限'
              )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM24, 'ANALAM24', 'DB1V', 'ANALAM24')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM24)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI405', 'DB1S', 'TI405', '冷凝器出口温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI405, 'TI405', 'DB1S', 'TI405')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI405)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(
                2,
                'ANALAM23',
                'DB1V',
                'ANALAM23',
                '冷凝器出口温度上限'
              )},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM23, 'ANALAM23', 'DB1V', 'ANALAM23')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM23)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("产品罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN13 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN13 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI405', 'DB1S', 'LI405', '产品罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI405, 'LI405', 'DB1S', 'LI405')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI405)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM25', 'DB1V', 'ANALAM25', '产品罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM25, 'ANALAM25', 'DB1V', 'ANALAM25')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM25)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("中间罐液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN14 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN14 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI404', 'DB1S', 'LI404', '中间罐液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI404, 'LI404', 'DB1S', 'LI404')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI404)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM27', 'DB1V', 'ANALAM27', '中间罐液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM27, 'ANALAM27', 'DB1V', 'ANALAM27')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM27)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("反应釜液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN14 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN14 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI404', 'DB1S', 'LI404', '反应釜液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI404, 'LI404', 'DB1S', 'LI404')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI404)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM27', 'DB1V', 'ANALAM27', '反应釜液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM27, 'ANALAM27', 'DB1V', 'ANALAM27')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM27)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("反应釜内温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN16 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN16 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI402', 'DB1S', 'TI402', '反应釜内温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI402, 'TI402', 'DB1S', 'TI402')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI402)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM31', 'DB1V', 'ANALAM31', '反应釜内温度上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM31, 'ANALAM31', 'DB1V', 'ANALAM31')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM31)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("反应釜内压力")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN32 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN32 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1"}),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'PI401', 'DB1S', 'PI401', '反应釜内压力实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.PI401, 'PI401', 'DB1S', 'PI401')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.PI401)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM63', 'DB1V', 'ANALAM63', '反应釜内压力上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM63, 'ANALAM63', 'DB1V', 'ANALAM63')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM63)+" ")]),_c('div',{staticClass:"item1 colorl"}),_c('div',{staticClass:"item2"})])])])]),_c('div',{staticClass:"content1"},[_c('div',{staticClass:"left-box"},[_c('div',{staticClass:"lie1"},[_vm._v("公用")]),_c('div',{staticClass:"lie2"},[_vm._m(2),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("储气罐压力")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN29 ? 'green' : 'red'
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN29 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM58', 'DB1V', 'ANALAM58', '储气罐压力下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM58, 'ANALAM58', 'DB1V', 'ANALAM58')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM58)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI101', 'DB1S', 'LI101', '储气罐压力实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI101, 'LI101', 'DB1S', 'LI101')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI101)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM57', 'DB1V', 'ANALAM57', '储气罐压力上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM57, 'ANALAM57', 'DB1V', 'ANALAM57')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM57))]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"},[_vm._v("压力高放空")])]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("共用水箱液位")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN33 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN33 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM66', 'DB1V', 'ANALAM66', '共用水箱液位下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM66, 'ANALAM66', 'DB1V', 'ANALAM66')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM66)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'LI001', 'DB1S', 'LI001_DCS', '共用水箱液位实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.LI001_DCS, 'LI001', 'DB1S', 'LI001_DCS')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.LI001_DCS)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM65', 'DB1V', 'ANALAM65', '共用水箱液位上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM65, 'ANALAM65', 'DB1V', 'ANALAM65')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM65))]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("锅炉蒸汽温度")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN34 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN34 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM68', 'DB1V', 'ANALAM68', '锅炉蒸汽温度下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM68, 'ANALAM68', 'DB1V', 'ANALAM68')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM68)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'TI314', 'DB1S', 'TI314', '锅炉蒸汽温度实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.TI314, 'TI314', 'DB1S', 'TI314')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.TI314)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM67', 'DB1V', 'ANALAM67', '锅炉蒸汽温度上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM67, 'ANALAM67', 'DB1V', 'ANALAM67')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM67))]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})]),_c('div',{staticClass:"hang2"},[_c('div',{staticClass:"item11"},[_vm._v("汽包压力")]),_c('div',{staticClass:"item1"},[_c('div',{staticClass:"qiechu",style:({
                backgroundColor: _vm.infoList.DB1V.DGALMEN35 ? 'green' : 'red',
              })},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.DGALMEN35 ? "使能" : "切除")+" ")])]),_c('div',{staticClass:"item1 colorl1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM69', 'DB1V', 'ANALAM69', '汽包压力下限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM69, 'ANALAM69', 'DB1V', 'ANALAM69')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM69)+" ")]),_c('div',{staticClass:"item1 colorl",on:{"click":function($event){return _vm.toCompon(2, 'PI305', 'DB1S', 'PI305', '汽包压力实时值')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1S.PI305, 'PI305', 'DB1S', 'PI305')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1S.PI305)+" ")]),_c('div',{staticClass:"item1",on:{"click":function($event){return _vm.toCompon(2, 'ANALAM70', 'DB1V', 'ANALAM70', '汽包压力上限')},"dblclick":function($event){return _vm.Cclick(_vm.infoList.DB1V.ANALAM70, 'ANALAM70', 'DB1V', 'ANALAM70')}}},[_vm._v(" "+_vm._s(_vm.infoList.DB1V.ANALAM70))]),_c('div',{staticClass:"item1"}),_c('div',{staticClass:"item2"})])])])]),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.nodename,"Lkname":_vm.Aname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hang1"},[_c('div',{staticClass:"item11"},[_vm._v("回路名称")]),_c('div',{staticClass:"item1"},[_vm._v("使用开关")]),_c('div',{staticClass:"item1"},[_vm._v("下限")]),_c('div',{staticClass:"item1"},[_vm._v("实时值")]),_c('div',{staticClass:"item1"},[_vm._v("上限")]),_c('div',{staticClass:"item1"},[_vm._v("联锁值")]),_c('div',{staticClass:"item2"},[_vm._v("联锁动作")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hang1"},[_c('div',{staticClass:"item11"},[_vm._v("回路名称")]),_c('div',{staticClass:"item1"},[_vm._v("使用开关")]),_c('div',{staticClass:"item1"},[_vm._v("下限")]),_c('div',{staticClass:"item1"},[_vm._v("实时值")]),_c('div',{staticClass:"item1"},[_vm._v("上限")]),_c('div',{staticClass:"item1"},[_vm._v("联锁值")]),_c('div',{staticClass:"item2"},[_vm._v("联锁动作")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hang1"},[_c('div',{staticClass:"item11"},[_vm._v("回路名称")]),_c('div',{staticClass:"item1"},[_vm._v("使用开关")]),_c('div',{staticClass:"item1"},[_vm._v("下限")]),_c('div',{staticClass:"item1"},[_vm._v("实时值")]),_c('div',{staticClass:"item1"},[_vm._v("上限")]),_c('div',{staticClass:"item1"},[_vm._v("联锁值")]),_c('div',{staticClass:"item2"},[_vm._v("联锁动作")])])}]

export { render, staticRenderFns }